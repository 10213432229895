const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt"},
        
        {name:"Product",divider:'Product Pages',icon:'',link:''},
        {name:"Products Display",link:"",icon:"fas fa-cube",childs:[
            {name:"All",link:"AllProduct"},
            {name:"Wine",link:"WineProduct"},
            {name:"Spirit Liquer",link:"SpiritProduct"},
            {name:"Water",link:"WaterProduct"},
            {name:"Other",link:"OtherProduct"},
        ]},
        {name:"Product Settings", icon:"fas fa-list-alt",childs:[
            {name:"Categories",link:"BoCategories"},
            {name:"Brand",link:"BoBrand"},
            {name:"Varietals",link:"BoGrape"},
            {name:"Dry Scale",link:"BoDryScale"},
            {name:"Body",link:"BoBodySize"},
            {name:"Country",link:"BoCountry"},
            {name:"Region",link:"BoRegion"},
            {name:"Pagination",link:"BoPagination"},
        ]},
        {name:"Event Packages",link:"BoPackage",icon:"fas fa-box"},

        {name:"Pages",divider:'Static Pages',icon:'',link:''},
        {name:"Home Page",link:"BoHome",icon:"fas fa-home"},
        {name:"Discover",link:"",icon:"fas fa-paste",childs:[
            {name:"About Us",link:"BoAboutUs"},
            {name:"Clients",link:"BoClientsLogo"},
            {name:"Event",link:"AppPubEvent"},
            {name:"Blog",link:"AppPubPost"},
            {name:"Customer Favorite",link:"BoFavorite"},
            {name:"FAQ",link:"BoFaq"},
        ]},        
        {name:"Partnership",link:"",icon:"fas fa-handshake",childs:[
            {name:"Become An Affiliate",link:"BoAffiliate"},
            {name:"Become Our Wineries",link:"BoWineries"},
        ]},
        {name:"Campaign",link:"",icon:"fas fa-bullhorn",childs:[
            {name:"Campaign List",link:"AppCampaign"},
            // {name:"Campaign Group",link:"AppCampaignGroup"},
            {name:"Campaign Inbox",link:"AppCampaignInbox"},
        ]},
        {name:"Contact Us",link:"BoContact",icon:"fas fa-phone"},
        {name:"Legal Page",link:"BoLegal",icon:"fas fa-newspaper"},
        {name:"User Register",link:"AppUserRegister",icon:"fas fa-users"},

        {name:"Settings",divider:'Settings',icon:'',link:''},
        {name:"Settings", icon:"fas fa-cog",childs:[
            {name:"SEO",link:"StaticSeo"},
            {name:"Template",link:"AppTemplate"},
            {name:"Web Setting",link:"WebSetting"},
        ]},
        {name:"User Management", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig