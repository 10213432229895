<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "AppCampaignInbox",
		extends: Base,
		data() {
			return {
				Name: "AppCampaignInbox",
				mrGroup: [],
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'filter.start_date'(v) {
				if (!v || !this.filter.end_date) return;
				this.search()
			},
			'filter.end_date'(v) {
				if (!v || !this.filter.start_date) return;
				if (this.filter.start_date > v) return swal('Filter', 'The start date is greater than the end date.',
					'warning')
				if (!this.$route.params.id) this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-4">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-md-2">
									<DatePicker v-model="filter.start_date" placeholder="Start Date"></DatePicker>
								</div>
								<div class="col-md-2">
									<DatePicker v-model="filter.end_date" placeholder="End Date"></DatePicker>
								</div>
								<!-- <div class="col-sm-1">
									<select2 :options="mrLevel" :object="['bul_id','bul_name']" v-model="filter.level">
										<option value="">-- Select Type --</option>
									</select2>
								</div> -->
								<div class="col-sm-3">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" v-on:keyup.enter="search"
												class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="aci_name"></SortField>
									</th>
									<th>Email</th>
									<th>Campaign</th>
									<th>Tags</th>
									<th>Date</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.aci_name||"-")}}</td>
									<td>{{(v.aci_email||"-")}}</td>
									<td>
										<ImagePreview v-if="v.aci_campaign_title_type=='image'"
											:src="uploader(v.aci_campaign_title)"> </ImagePreview>
										<span v-else>{{(v.aci_campaign_title||'-')}}</span>
									</td>
									<td>
										<template v-for="(v2,k2) in v.aci_campaign_tags">
											<span v-if="v2" :key="k2" class="label label-info">{{v2}}</span>
											<span v-else :key="k2">-</span>
										</template>

									</td>
									<td>{{(v.aci_create_at||"").dates('format2')}}</td>
									<td class="btn-action">
										<router-link class="icon_action" :to="{name:Name,params:{id:v.id}}"
											v-tooltip="'Detail'"><i class="icon-eye"></i></router-link>
										<a href="javascript:;" @click="deleteItem($event,k)"
											class="text-inverse icon_action act_icon" title="" data-toggle="tooltip"
											data-original-title="Delete"><i class="ti-trash"></i></a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="panel-body">
						<div class="pull-right">
							<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{"Detail "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6" v-if="row.aci_campaign_title">
							<BoField name="aci_campaign_title">
								<ImagePreview v-if="row.aci_campaign_title_type=='image'" :src="uploader(row.aci_campaign_title)">
								</ImagePreview>
								<span v-else>{{(row.aci_campaign_title||"-")}}</span>
							</BoField>
						</div>
						<div class="col-sm-6" v-if="row.aci_campaign_sub_campaign">
							<BoField name="aci_campaign_sub_campaign">
								<span>{{(row.aci_campaign_sub_campaign||"-")}}</span> </BoField>
						</div>
						<div class="col-sm-6" v-if="row.aci_campaign_tags">
							<BoField name="aci_campaign_tags">
								<template v-for="(v,k) in row.aci_campaign_tags">
									<span :key="k" v-if="v" class="badge badge-info">{{(v||"-")}}</span>
									<span :key="k" v-else>-</span>

								</template>
							</BoField>
						</div>
						<div class="col-sm-6" v-if="row.aci_campaign_url">
							<BoField name="aci_campaign_url"><span>{{(row.aci_campaign_url||"-")}}</span></BoField>
						</div>
						<div class="col-sm-6" v-for="(v,k) in row.aci_submit" :key="k">
							<BoField :label="v.name">
								<span v-if="v.type=='currency'">Rp {{(v.value||0).format()}}</span>
								<span v-else>{{(v.value||"-")}}</span>
							</BoField>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>